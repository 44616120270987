import React, {useContext,useEffect} from 'react';
import { useNavigate } from 'react-router-dom'
import {firebaseAuth} from '../../providers/AuthProvider';
import { ArrowHeadRightIcon, Spacer } from '../../Constants';
import Button from 'react-bootstrap/Button';

const Profile = () => {
  const navigate = useNavigate();
useEffect(() => {
  if (!user.isAuthenticated) {    
    navigate('/manage/sign-in')
  }
}, []);
  const {user, handleSignout} = useContext(firebaseAuth)
  const handleSignOut = (e) => {
    e.preventDefault()
    handleSignout()
  } 
  return (
    <div className="container">
          <div className="py-4"></div>
          <h1>{user.email}</h1>
          <div className="py-3"></div>
          
          <Button onClick={handleSignOut}><i className="light"><ArrowHeadRightIcon fill="#FFFFFF" /></i> <span>Logoff</span></Button>

    </div>
  );
};

export default Profile;