import React, { useContext, useEffect, useState } from "react";
import { Navigate } from 'react-router-dom'
import { firebaseAuth } from '../../../../providers/AuthProvider';
import '../../../../assets/css/reports.css';
import firebase from "firebase/compat/app";
import { GetDeviceId } from "../../../../Constants";
import moment from "moment";

const CampaignCodeReport = () => {
  const [ eventData, setEventData ] = useState(null);
  const [eventReportData, setEventReportData] = useState(null);
  const { user } = useContext(firebaseAuth);

  useEffect(() => {

  }, []);

  if (!user.isAuthenticated)
    return <Navigate to="/manage/sign-in" />
  if (user?.role > 1)
    return (
      <div className='container'>
      <h1>Campaign Code Report</h1>
      <div className="py-3"></div>
      <div className="alert alert-danger" >You do not have permission to access this report.</div>
      
    </div>
    );
  return (
    <div className='container'>
      <h1>Campaign Code Report</h1>
      <div className="py-3"></div>
      <div>Coming Soon</div>
      
    </div>
  );
};
export default CampaignCodeReport;
