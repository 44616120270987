import React, { useState, useEffect,lazy,Suspense } from "react";
import { Route, Routes, Link } from "react-router-dom";
import { PinIcon, ArrowHeadRightIcon } from "../../Constants";
import { Navbar,Nav, NavDropdown } from "react-bootstrap";
import { db } from "../../firebase";
import TagManager from 'react-gtm-module'
import "../../assets/css/main.css";
// import packageJson from '../../../package.json';
import moment from "moment";

const Home = lazy(() => import('./Home'));
const Register = lazy(() => import('./Register'));
const Faq = lazy(() => import('./Faq'));
const GalleryPublic = lazy(() => import('./GalleryPublic'));
const Terms = lazy(() => import('./Terms'));
const CheckIn = lazy(() => import('./CheckIn'));
const Waiver = lazy(() => import('./Waiver'));
const Watch = lazy(() => import('./Watch'));
const Discover = lazy(() => import('./Discover'));
const Contact = lazy(() => import('./Contact'));
const Confirmation = lazy(() => import('./Confirmation'));
const Verify = lazy(() => import('./Verify'));

const Accessibility = lazy(() => import('./Accessibility'));
const Oops = lazy(() => import('./Oops'));
// const ScrollToTop = lazy(() => import('../ScrollToTop'));
const MainLayout = (props) => {
  const [cookieBannerAck, setCookieBannerAck] = useState(false);
  const [listedEvents, setListedEvents] = useState([]);
  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [expanded,setExpanded] = useState(false);
  const closeBanner = () => {
    localStorage.setItem("pcna-cookie-banner-ack", true);
    setCookieBannerAck(true);
  };
  const tagManagerArgs = {
    gtmId: 'GTM-KL52H5F'
}

TagManager.initialize(tagManagerArgs)
// const caching= ()=> {
//   console.log(packageJson.version);
//   let version = localStorage.getItem('version');
//       if(version!=packageJson.version)
//       {
//           if('caches' in window){
//            caches.keys().then((names) => {
//           // Delete all the cache files
//           names.forEach(name => {
//               caches.delete(name);
//           })
//       });
  
//       // Makes sure the page reloads. Changes are only visible after you refresh.
//       window.location.reload(true);
//   }
  
//         localStorage.clear();
//         localStorage.setItem('version',packageJson.version);
//       }
//   };
  // const handleCallback = (childData) => {
  //   setExpMenu(childData);
  // };
  useEffect(() => {
    // caching();
    db.collection("events")
    .where("status", "==", "Active")
    .where("allowPublic", "==", true)
    .where("listedEvent", "==", true)
    .orderBy("sortOrder")
    .get()
    .then((qs) => {
      if (qs.docs.length > 0) {
        let tmpItems = [];
        let tmpFeaturedItems = [];
        qs.docs.map((item, index) => {
          let tmpItem = item.data();
          tmpItem.id = item.id;
          tmpItems.push(tmpItem);
          if (tmpItem.featuredEvent) tmpFeaturedItems.push(tmpItem);
        });
        // props.parentCallback(tmpItems);
        setListedEvents(tmpItems);
        tmpFeaturedItems.unshift({
          name:"Welcome to eZentrum:",
          name2:"The Home of Porsche Experiences",
          bannerName:"Welcome to eZentrum:",
          bannerSubtitle:"Explore past and upcoming experiences and discover opportunities to connect with the living legacy of our vehicles. On the road and off, we invite you to bring your passions to life across design, culture, travel, sport, and more.",
          subtitle:"Explore past and upcoming experiences and discover opportunities to connect with the living legacy of our vehicles. On the road and off, we invite you to bring your passions to life across design, culture, travel, sport, and more.",
          bannerImage:"https://res.cloudinary.com/dcx7bbpx8/image/upload/v1621365928/PCNA/ezentrum/eZentrum_IntroGraphic_576x432_shadowfortext-2.jpg",
          mobileBannerImage:"https://res.cloudinary.com/dcx7bbpx8/image/upload/v1621365928/PCNA/ezentrum/eZentrum_IntroGraphic_576x432_shadowfortext-2.jpg",
          publicUrl:"#experiences",
          homeSlide:true
        });

        setFeaturedEvents(tmpFeaturedItems);
        // setLoading(false);
      } else {
        // setLoading(false);
        // return history("/");
      }
    })
    .catch((e) => {
      console.log(e);
      // setLoading(false);
      // return     history("/");
    });
    setCookieBannerAck(
      localStorage.getItem("pcna-cookie-banner-ack") ? true : false
    );
    
  }, []);
  return (
    <div id="main-layout">

      <div className={cookieBannerAck ? "d-none" : "cookie-container"}>
        <div className="container">
        This website uses cookies. We do this to better understand how visitors use our site and to offer you a more personal experience. We share information about your use of our site with social media and analytics partners in accordance with our
        {" "}
          <a href="https://www.porsche.com/usa/privacy-policy/" target="_blank">
            Privacy Notice
          </a>
          .
          <div className="cookie-close" onClick={closeBanner}>
            X
          </div>
        </div>
      </div>
      <div id="header">
        <div className="container-xxl">
          <div className="header-logo">
           
                <a href="/" title="Home" className="">
            
                <picture>
  <source media="(min-width:1200px)" srcSet="https://res.cloudinary.com/dcx7bbpx8/image/upload/f_auto,q_auto/v1/PCNA/ezentrum/t2suvcq2bkqofl7luwyb" />
  <img src="https://res.cloudinary.com/dcx7bbpx8/image/upload/f_auto,q_auto/v1/PCNA/ezentrum/t2suvcq2bkqofl7luwyb" alt="{Porsche Cars North America" />
</picture>
                        {/* <picture>
  <source media="(min-width:1200px)" srcSet="https://res.cloudinary.com/dcx7bbpx8/image/upload/f_auto,q_auto/v1/PCNA/ezentrum/t2suvcq2bkqofl7luwyb" />
  <img src="https://res.cloudinary.com/dcx7bbpx8/image/upload/f_auto,q_auto/v1/PCNA/ezentrum/jnhuwo7fuvwmskm6jweo" alt="{Porsche Cars North America" />
</picture> */}

                </a>
           
           
          </div>

          <Navbar expand="lg" className="navbar navbar-expand-lg navbar-toggleable-lg navbar-light bg-white">
            {/* <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button> */}
            {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
  <Navbar.Toggle onClick={() => { setExpanded(!expanded)}} aria-controls="basic-navbar-nav" className={expanded ? "fixed":""}/>
            <Navbar.Collapse id="basic-navbar-nav">

            {/* <div className="navbar-collapse collapse d-lg-inline-flex justify-content-between"> */}
            <Nav className="navbar-nav flex-grow-1">

              {/* <ul className="navbar-nav flex-grow-1"> */}
                <NavDropdown title="Experiences" className="d-none d-lg-flex mb-4 mb-lg-0" id="basic-nav-dropdown">
                  {listedEvents?.map((item, index) => ( 
                  <NavDropdown.Item key={index}>
                    <Link to={"/" + item.publicUrl} className="nav-link">
                      {item.name}
                    </Link>
                  </NavDropdown.Item>
                  ))}
                </NavDropdown>
                <div className="py-5 d-lg-none"></div>
                <li className="nav-item d-lg-none pt-5 mb-3 mb-lg-0">
                  <Link className="nav-link" to={"/#experiences"} onClick={() => { document.getElementById('basic-navbar-nav').classList.toggle("show"); }}>
                  Experiences
                  </Link>
                </li>
                <li className="nav-item my-3 my-lg-0">
                  <Link className="nav-link" to={"/watch"}  onClick={() => { document.getElementById('basic-navbar-nav').classList.toggle("show"); }}>
                    Watch
                  </Link>
                </li>
                <li className="nav-item my-3 my-lg-0">
                  <Link className="nav-link" to={"/discover"}  onClick={() => { document.getElementById('basic-navbar-nav').classList.toggle("show"); }}>
                    Discover More
                  </Link>
                </li>
                <li className="nav-item my-3 my-lg-0">
                  <Link className="nav-link" to={"/contact"}  onClick={() => { document.getElementById('basic-navbar-nav').classList.toggle("show"); }}>
                    Contact Us
                  </Link>
                </li>
              </Nav>
              </Navbar.Collapse>
              <ul className="navbar-nav dealer-nav d-none d-lg-block">
                <li className="nav-item align-self-end">
                  <a
                    className="nav-link"
                    href="https://www.porsche.com/usa/dealersearch/"
                    target="_blank"
                    onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Header','action':'External Link','label':'https://www.porsche.com/usa/dealersearch/'});}}

                  >
                    <i className="md">
                      <PinIcon />
                    </i>{" "}
                    Find a Dealer
                  </a>
                </li>
              </ul>
            {/* </div> */}
            
        </Navbar>
        </div>
      </div>
      <div className="main-container">
        <Suspense fallback={<div id="loading-container">
            <img
              src="/assets/images/loading.gif"  width="100"
              alt="Porsche Cars North America"
            />
          </div>}>
        {/* <ScrollToTop /> */}

        <Routes>
          <Route
            exact
            sensitive={false}
            path="/"
            element={<Home listedEvents={listedEvents} featuredEvents={featuredEvents } />}
            // render={(props) => <Home listedEvents={listedEvents} featuredEvents={featuredEvents } />}
          />
          <Route exact path="/watch" element={<Watch/> } />
          <Route exact path="/discover" element={<Discover/> } />
          <Route exact path="/contact" element={<Contact/> } />
          <Route exact path="/contact/:topic" element={<Contact/> } />
          <Route exact path="/oops" element={<Oops/> } />
          <Route exact path="/checkin" element={<CheckIn/> } />

          <Route exact path="/accessibility" element={<Accessibility/> } />
          <Route exact path="/register/:id" element={<Register/> } />
          <Route exact path="/:id" element={<Home/> } />
          <Route exact path="/:id/faq" element={<Faq/> } />
          <Route exact path="/:id" element={<Home/> } />
          <Route exact path="/:id/faq" element={<Faq/> } />
          <Route exact path="/:id/terms" element={<Terms/> } />
          <Route exact path="/:id/gallery" element={<GalleryPublic/> } />
          <Route exact path="/checkin/:id" element={<CheckIn/> } />
          <Route
            exact
            path="/:id/confirmation/:regId"
            element={<Confirmation />}
          />
                   <Route
            exact
            path="/confirmation/:regId"
            element={<Confirmation />}
          />
                    <Route
            exact
            path="/verify/:regId"
            element={<Verify />}
          />
          <Route exact path="/waiver/:regId" element={<Waiver/> } />
        </Routes>
        </Suspense>
      </div>

      <footer className="footer fs-sm">
        <div className="footer-light pt-2">
          <div className="container">
            <hr />
            <div className="py-3"></div>
            <div className="row">
              <div className="col-auto">
                <div>
                  <Link className="footer-text" to={"/"}>
                    <i className="xxs theme s2 link-footer">
                      <ArrowHeadRightIcon />
                    </i>
                    <span>Experiences</span>
                  </Link>
                </div>
                <div>
                  <Link className="footer-text" to={"/watch"}>
                    <i className="xxs theme s2 link-footer">
                      <ArrowHeadRightIcon />
                    </i>
                    <span>Watch</span>
                  </Link>
                </div>
                <div>
                  <Link className="footer-text" to={"/discover"}>
                    <i className="xxs theme s2 link-footer">
                      <ArrowHeadRightIcon />
                    </i>
                    <span>Discover More</span>
                  </Link>
                </div>
                <div>
                  <Link className="footer-text" to={"/contact"}>
                    <i className="xxs theme s2 link-footer">
                      <ArrowHeadRightIcon />
                    </i>
                    <span>Contact Us</span>
                  </Link>
                </div>
                <div>
                  <Link className="footer-text" to={"/accessibility"}>
                    <i className="xxs theme s2 link-footer">
                      <ArrowHeadRightIcon />
                    </i>
                    <span>Accessibility</span>
                  </Link>
                </div>
              </div>

              <div className="col-auto">
                <div>
                  <a
                    target="_blank"
                    className="footer-text"
                    href="https://www.porsche.com/usa/"
                    onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'https://www.porsche.com/usa/'});                           }}
                  >
                    <i className="xxs theme s2 link-footer">
                      <ArrowHeadRightIcon />
                    </i>
                    <span>Porsche USA</span>
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    className="footer-text"
                    href="https://www.porsche.com/dreams/en-US"
                    onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'https://www.porsche.com/dreams/en-US'});                           }}
                  >
                    <i className="xxs theme s2 link-footer">
                      <ArrowHeadRightIcon />
                    </i>
                    <span>Porsche Brand Hub</span>
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    className="footer-text"
                    href="https://www.porschedriving.com"
                    onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'https://www.porschedriving.com/home'});                           }}
                  >
                    <i className="xxs theme s2 link-footer">
                      <ArrowHeadRightIcon />
                    </i>
                    <span>Porsche Experience Center</span>
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    className="footer-text"
                    href="https://www.porschedriving.com/birmingham/"
                    onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'https://www.porschedriving.com/birmingham/'});                           }}
                  >
                    <i className="xxs theme s2 link-footer">
                      <ArrowHeadRightIcon />
                    </i>
                    <span>Porsche Track Experience</span>
                  </a>
                </div>

                <div>
                  <a
                    target="_blank"
                    className="footer-text"
                    href="http://www.porschedriving.com/travel-experience"
                    onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'http://www.porschedriving.com/travel-experience'});                           }}
                  >
                    <i className="xxs theme s2 link-footer">
                      <ArrowHeadRightIcon />
                    </i>
                    <span>Porsche Travel Experience USA</span>
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    className="footer-text"
                    href="http://porschedealer.com/dealer/usLocator/?zip=&amp;page=search"
                    onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'http://porschedealer.com/dealer/usLocator/?zip=&amp;page=search'});                           }}
                  >
                    <i className="xxs theme s2 link-footer">
                      <ArrowHeadRightIcon />
                    </i>
                    <span>Locate a Dealer</span>
                  </a>
                </div>

                <div>
                  <a
                    target="_blank"
                    className="footer-text"
                    href="http://www.porsche.com/usa/modelstart/"
                    onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'http://www.porsche.com/usa/modelstart/'});                           }}
                  >
                    <i className="xxs theme s2 link-footer">
                      <ArrowHeadRightIcon />
                    </i>
                    <span>Build Your Porsche</span>
                  </a>
                </div>
              </div>
              <div className="col"></div>
              <div className="col-auto">
                <div className="row">
                  <div className="col-12 px-md-0 fs-lg">Connect with Porsche</div>
                </div>
                <div className="row">
                  <div className="col pl-md-0 pe-1">
                    <a target="_blank" href="https://twitter.com/Porsche" onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'https://twitter.com/Porsche"'});}}>
                      {/* <img src="/assets/images/twitter.jpg" /> */}
                      <div className="social-icon tw" >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  width="100%" height="100%"><path d="M3 17.82a10.88 10.88 0 0 0 5.81 1.68 9.9 9.9 0 0 0 7.55-3.27 10.45 10.45 0 0 0 2.86-7.84v-.14a6.26 6.26 0 0 0 1.78-2 5.31 5.31 0 0 1-2.06.62 3.35 3.35 0 0 0 .91-.87 3.36 3.36 0 0 0 .64-1.2 5.91 5.91 0 0 1-1.15.55 3.52 3.52 0 0 1-1.15.26 3.7 3.7 0 0 0-2.72-1.11 3.53 3.53 0 0 0-2.6 1.11 3.63 3.63 0 0 0-1.1 2.69 4.12 4.12 0 0 0 .09.86 10.4 10.4 0 0 1-4.33-1.1A9.6 9.6 0 0 1 4 5.22a4.17 4.17 0 0 0-.31 2.93 3.52 3.52 0 0 0 1.43 2.07 1.9 1.9 0 0 1-1.59-.43 3.86 3.86 0 0 0 .63 2.26 3.72 3.72 0 0 0 2.23 1.49 2.38 2.38 0 0 1-1.55.09 2.72 2.72 0 0 0 1.1 1.76 3.41 3.41 0 0 0 2.23.84 5.31 5.31 0 0 1-2 1.3 5.71 5.71 0 0 1-3.17.29z"></path></svg>
                      </div>
                    </a>
                  </div>
                  <div className="col pl-0 pe-1">
                    <a target="_blank" href="http://instagram.com/porsche/" onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'http://instagram.com/porsche/'});}}>
                    <div className="social-icon ig" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%"><path d="M12 4.62c-2.4 0-2.69 0-3.64.06A4.8 4.8 0 0 0 6.7 5a2.81 2.81 0 0 0-1 .68 2.74 2.74 0 0 0-.67 1 5 5 0 0 0-.31 1.67v7.27A5.06 5.06 0 0 0 5 17.3a2.74 2.74 0 0 0 .67 1 2.81 2.81 0 0 0 1 .68 5.05 5.05 0 0 0 1.66.3c1 .05 1.24.06 3.64.06s2.69 0 3.64-.06a4.92 4.92 0 0 0 1.67-.3A3 3 0 0 0 19 17.3a5 5 0 0 0 .31-1.67c0-1 .05-1.23.05-3.64s0-2.68-.05-3.63A5.06 5.06 0 0 0 19 6.69 3 3 0 0 0 17.31 5a5.13 5.13 0 0 0-1.67-.3c-.95-.07-1.24-.08-3.64-.08zM12 3c2.45 0 2.75 0 3.71.06a6.61 6.61 0 0 1 2.19.41 4.64 4.64 0 0 1 2.63 2.63 6.66 6.66 0 0 1 .41 2.19c0 1 .06 1.26.06 3.71s0 2.75-.06 3.71a6.61 6.61 0 0 1-.41 2.19 4.64 4.64 0 0 1-2.63 2.63 6.61 6.61 0 0 1-2.19.41c-1 0-1.26.06-3.71.06s-2.75 0-3.71-.06a6.66 6.66 0 0 1-2.19-.41 4.64 4.64 0 0 1-2.63-2.63 6.61 6.61 0 0 1-.41-2.19C3 14.75 3 14.45 3 12s0-2.75.06-3.71a6.54 6.54 0 0 1 .41-2.18 4.37 4.37 0 0 1 1-1.6 4.42 4.42 0 0 1 1.59-1 6.93 6.93 0 0 1 2.19-.41C9.25 3 9.55 3 12 3zm0 4.38A4.62 4.62 0 1 1 7.38 12 4.62 4.62 0 0 1 12 7.38zM12 15a3 3 0 1 0-3-3 3 3 0 0 0 3 3zm5.89-7.81a1.08 1.08 0 1 1-1.08-1.08 1.08 1.08 0 0 1 1.08 1.08z"></path></svg>                      </div>                    </a>
                  </div>
                  <div className="col pl-0 pe-1">
                    <a target="_blank" href="https://www.facebook.com/porsche" onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'https://www.facebook.com/porsche'});}}>
                    <div className="social-icon fb" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%"><path d="M7.5 12.44h2.42V21h3.27v-8.56H16l.51-3.21h-3.32V7a.71.71 0 0 1 .38-.68 1.6 1.6 0 0 1 .77-.21h2.12V3H14a5.38 5.38 0 0 0-2 .36 2.84 2.84 0 0 0-1.3 1 4.16 4.16 0 0 0-.59 1.2 4.51 4.51 0 0 0-.17 1.23v2.44H7.5v3.21z"></path></svg>                      </div>
                    </a>
                  </div>
        
                  <div className="col pl-0 pe-1">
                    <a
                      target="_blank"
                      href="https://www.youtube.com/user/Porsche" onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'https://www.youtube.com/user/Porsche'});}}
                    >
                    <div className="social-icon yt" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%"><path d="M20.82 8.59a3.56 3.56 0 0 0-.72-1.69 2.66 2.66 0 0 0-1.8-.73C15.78 6 12 6 12 6s-3.78 0-6.3.17a2.69 2.69 0 0 0-1.81.73 3.51 3.51 0 0 0-.71 1.69A24.46 24.46 0 0 0 3 11.35v1.29a24.75 24.75 0 0 0 .18 2.77 3.6 3.6 0 0 0 .72 1.69 3.13 3.13 0 0 0 2 .73C7.32 18 12 18 12 18s3.78 0 6.3-.18a2.63 2.63 0 0 0 1.8-.72 3.6 3.6 0 0 0 .72-1.69 24.52 24.52 0 0 0 .18-2.77v-1.29a24.46 24.46 0 0 0-.18-2.76zm-10.68 5.62V9.42l4.86 2.4z"></path></svg>                      </div>                    </a>
                  </div>
                  <div className="col pl-0 pe-1">
                    <a target="_blank" href="http://www.pinterest.com/porsche/" onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'http://www.pinterest.com/porsche/'});}}>
                    <div className="social-icon p" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%"><path d="M5.25 9.47a5.47 5.47 0 0 0 .52 2.44 3 3 0 0 0 1.54 1.5.32.32 0 0 0 .5-.24 2.44 2.44 0 0 1 .08-.44 2 2 0 0 1 .12-.4.56.56 0 0 0-.16-.56 3.13 3.13 0 0 1-.66-2 4.88 4.88 0 0 1 1.38-3.5 4.67 4.67 0 0 1 3.55-1.45 4.19 4.19 0 0 1 3.08 1.1 3.9 3.9 0 0 1 1.11 2.88 7.76 7.76 0 0 1-.9 3.88 2.6 2.6 0 0 1-2.28 1.59 1.61 1.61 0 0 1-1.3-.58 1.52 1.52 0 0 1-.3-1.39c.08-.32.2-.75.35-1.27s.29-1 .39-1.39a4 4 0 0 0 .16-1 1.74 1.74 0 0 0-.35-1.09 1.23 1.23 0 0 0-1-.44 1.69 1.69 0 0 0-1.38.77 3.14 3.14 0 0 0-.56 1.89 4.91 4.91 0 0 0 .08.86 1.43 1.43 0 0 0 .19.58l.08.2Q8.36 16.15 8.16 17a11.2 11.2 0 0 0 0 3.94.09.09 0 0 0 .08.1.16.16 0 0 0 .15 0 10.54 10.54 0 0 0 1.79-3.41c.49-1.75.74-2.69.74-2.82a2.19 2.19 0 0 0 .95.9 2.93 2.93 0 0 0 1.49.39 4.65 4.65 0 0 0 3.88-2 8.25 8.25 0 0 0 1.51-5.11A5.64 5.64 0 0 0 17 4.77 6.19 6.19 0 0 0 12.43 3a6.92 6.92 0 0 0-5.26 2 6.33 6.33 0 0 0-1.92 4.47z"></path></svg>
                      </div>                    </a>
                  </div>
                  <div className="col pl-0 pe-0">
                    <a target="_blank" href="https://www.linkedin.com/company/porsche" onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'https://www.linkedin.com/company/porsche'});}}>
                    <div className="social-icon li" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%"><path d="M3.3 9H7v12H3.3zm1.86-6A2.17 2.17 0 1 1 3 5.17 2.16 2.16 0 0 1 5.16 3zm4.21 6H13v1.64a3.9 3.9 0 0 1 3.53-1.94c3.77 0 4.47 2.49 4.47 5.73V21h-3.73v-5.85c0-1.39 0-3.18-1.94-3.18s-2.23 1.51-2.23 3.08V21H9.37V9z"></path></svg>                      </div>                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-3"></div>
          </div>
        </div>
        <div className="footer-dark">
          <div className="container">
            <div className="pt-1"></div>

            <hr />
            <div className="py-2"></div>
            <div className="row">
              <div className="col-auto">
                <span>&copy;{moment().year()} Porsche Cars North America, Inc.</span>
              </div>
              <div className="col-md-auto col-12">
                <a
                  className="underline"
                  href="https://www.porsche.com/usa/legal-notice/"
                  target="_blank"
                  onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'https://www.porsche.com/usa/legal-notice/'});}}
                >
                  Legal Notice
                </a>
              </div>
              <div className="col-md-auto col-12">
                <a
                  className="underline"
                  href="https://www.porsche.com/usa/privacy-policy/"
                  target="_blank"
                  onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'https://www.porsche.com/usa/privacy-policy/'});}}
                >
                  Cookie Policy
                </a>
              </div>
              <div className="col-md-auto col-12">
                <a
                  className="underline"
                  href="https://www.porsche.com/usa/privacy-policy/"
                  target="_blank"
                  onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'https://www.porsche.com/usa/privacy-policy/'});}}
                >
                  California Privacy
                </a>
              </div>
              <div className="col-md-auto col-12">
                <a
                  className="underline"
                  href="https://www.porsche.com/usa/privacy-policy/"
                  target="_blank"
                  onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'https://www.porsche.com/usa/privacy-policy/'});}}
                >
                  Privacy Notice
                </a>
              </div>
            </div>
            <div className="py-3 py-md-2"></div>
            <div className="row">
              <div className="col-md-auto col-12">
                <Link className="btn btn-default mb-3 mb-md-0" to={"/contact"}>
                  <i className="light">
                    <ArrowHeadRightIcon fill="#FFFFFF" />
                  </i>{" "}
                  <span>Contact Us</span>
                </Link>
              </div>
              
              <div className="col">
                <a
                  href="https://www.porsche.com/usa/privacy-policy/contact/"
                  target="_blank"
                  className="btn btn-default"
                  onClick = {() =>{ window.dataLayer.push({'event':'ga-event','category':'Footer','action':'External Link','label':'https://www.porsche.com/usa/privacy-policy/contact/'});}}
                >
                  <i className="light">
                    <ArrowHeadRightIcon fill="#FFFFFF" />
                  </i>{" "}
                  <span>Do Not Sell or Share My Personal Information</span>
                </a>
              </div>
            </div>
          </div>
          <div className="py-3"></div>
        </div>
      </footer>
    </div>
  );
};

export default MainLayout;
