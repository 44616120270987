import React, { useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { firebaseAuth } from "../../providers/AuthProvider";
import { db } from "../../firebase";
import Alert from "react-bootstrap/Alert";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";

const ForgotPassword = (props) => {
  const {
    inputs,
    setInputs,
    errors,
    setErrors,
    user,
    handleSignout
  } = useContext(firebaseAuth);
  const [redirect, setRedirect] = useState(false);
  

  useEffect(() => {
    if (user.isAuthenticated) 
      handleSignout();
  }, [user]);
  useEffect(() => {
    setInputs({ email: "", finished:false});
    setErrors([]);
  }, []);

  if (redirect) return <Navigate to="/manage" />;

  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    var forgotPassword = firebase.functions().httpsCallable("app/forgotPassword");
    forgotPassword(inputs.email).then(function (result) {
      console.log(result);
      setInputs((prev) => ({ ...prev, finished: true}));
    }).catch(function(e) {
      console.log('Error:',e);
      setInputs((prev) => ({ ...prev, finished: true}));
    });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInputs((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <div>
      <h1>Forgot Password</h1>
        <div className="py-3"></div>
      <div className={!inputs.finished ? "d-none":""}>
        <div className="row">
          <div className="col-auto">
            <div className="alert alert-success">A password reset link has been sent to the email address on file.<br/>  If you do not receive an email within 15 minutes contact a system adminisitrator.</div>
          </div>
        </div>
      </div>
    
    <form className={inputs.finished ? "d-none":""} onSubmit={handleSubmit}>
      <div className="container">
        
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="password">
                Email Address{" "}
              </label>
              <input
                type="text"
                id="email"
                name="email"
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="py-3"></div>
        <div className="row">
          <div className="col-auto">
            <button className="btn btn-theme" onClick={handleSubmit}>
              Send Password Reset Email
            </button>
          </div>
        </div>
        <div className="py-2"></div>
        <div className="row">
          <div className="col-auto">
          {errors[0] ? <Alert variant="danger">{errors[0]}</Alert> : ""}
          </div>
        </div>
      </div>
    </form>
    </div>
  );
};

export default ForgotPassword;
