import React, { useContext, useEffect, useState } from "react";
import { Navigate, Routes } from 'react-router-dom'
import { firebaseAuth } from '../../../providers/AuthProvider';
import '../../../assets/css/reports.css';
import firebase from "firebase/compat/app";
import { GetDeviceId } from "../../../Constants";
import moment from "moment";

const CampaignCodes = () => {
  const [ eventData, setEventData ] = useState(null);
  const [eventReportData, setEventReportData] = useState(null);
  const { user } = useContext(firebaseAuth);

  if (!user.isAuthenticated)
    return <Navigate to="/manage/sign-in" />
  return (
    <div className='container'>
      <h1>Reports</h1>
      <div className="py-3"></div>
      
        <div className="pb-5"><a href="/manage/campaign-codes/campaign-code-requests"><h3>Campaign Code Requests</h3></a></div>
        <div className="pb-5"><a href="/manage/campaign-codes/campaign-code-report"><h3>Campaign Code Report</h3></a></div>
        <div className="pb-5"><a href="/manage/campaign-codes/lead-activity-report"><h3>Lead Activity Report</h3></a></div>
      
      
    </div>
  );
};
export default CampaignCodes;
