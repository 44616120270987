import React, { Component } from 'react'

export const CurrentEventDataContext = React.createContext()

class CurrentEventDataProvider extends Component {
  

  state = {
    currentEventData: {galleryImages:[],ctaList:[],videoList:[],locationList:[],vehicleList:[],additionalFields:[]}
  }

  // Method to update state
  setCurrentEventData = (currentEventData) => {
    this.setState((prevState) => ({ currentEventData }))
  }

  render() {
    const { children } = this.props
    const { currentEventData } = this.state
    const { setCurrentEventData } = this

    return (
      <CurrentEventDataContext.Provider
        value={{
          currentEventData,
            setCurrentEventData,
        }}
      >
        {children}
      </CurrentEventDataContext.Provider>
    )
  }
}

// export default CurrentEventDataContext

export { CurrentEventDataProvider }