import React, { useContext, useEffect, useState } from "react";
import { Navigate,useParams } from 'react-router-dom'
import { db } from "../../../firebase";
import { DeleteIcon, InfoIcon } from "../../../Constants";
import { firebaseAuth } from '../../../providers/AuthProvider';
import '../../../assets/css/reports.css';
import firebase from "firebase/compat/app";
import { CurrentEventDataContext } from "../CurrentEventDataContext";

import { toast } from "react-toastify";
const Sessions = () => {
  const [ eventData, setEventData ] = useState(null);
  const [eventReportData, setEventReportData] = useState(null);
  const [regData, setRegData] = useState(null);
  const { user } = useContext(firebaseAuth);
  const { id } = useParams();
  const { currentEventData, setCurrentEventData } = useContext(CurrentEventDataContext);

  const handleGalleryImageRead = async (event, i) => {
    console.log(event.target.files);
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }
    for (let x = 0; x < files.length; x++) {
      console.log(files[x]);
      if (files[x].type !== "image/jpeg" && files[x].type !== "image/jpg" && files[x].type !== "image/png") {
        toast.error("Only jpeg and png files are allowed.");
        return;
      }
      const base64 = await convertBase64(files[x]);
      console.log('upating cloudinary');
      let j = i + x;
      console.log(eventData.eventGalleryImages);
      await uploadCloudinary(base64, eventData.id + "_eventGalleryImage_" + j, "/pcna/ezentrum/" + eventData.id + "/", "eventGalleryImage" + j, { crop: "fit", height: 900, width: 1600 }, 'eventGalleryImages', j);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const uploadCloudinary = async (file, pubId, folder, key, transformation, list, i, field) => {
    // console.log(pubId); console.log(folder); console.log(key); console.log(transformation);
    // console.log(list); console.log(i); console.log(field);
    //uploadCloudinary(base64, eventData.id + "_" + x + "_" + i, "/pcna/ezentrum/" + eventData.id + "/", x + i, null, true, i);
    let uploadData = { file: file, public_id: pubId, folder: folder, transformation: transformation };
    var upload = firebase.functions().httpsCallable("app/uploadCloudinary");
    let result = await upload(uploadData);

    // upload(uploadData)
    //   .then((result) => {
        if (result?.data.message) {
          toast.warning(result?.data.message);
          return false;
        } else {
          toast.success("Image upload successful.");
          let tmpInputs = eventData;
          let u = result.data.url;
          //.split("/");
          // u.splice(6,1);
          // u = u.join("/");
          if (!list)
            tmpInputs[key] = u;
          let img = document.getElementById(key);
          if (img) img.src = img.src + "?v=" + Date.now(); // trick browser into reload
          if (!list) {
            setEventData({ ...tmpInputs });
            db.collection("events").doc(tmpInputs.id).set(tmpInputs);
            setEventData(tmpInputs);
            setCurrentEventData(tmpInputs);
            sessionStorage.setItem("eventData", JSON.stringify(tmpInputs));
            return true;
          } else {
            let tmpImages = eventData[list];
            if (!field) {
              tmpImages[i] = u;
              // console.log(tmpImages);
              setEventData({ ...eventData, tmpImages: tmpImages });
            } else {
              console.log(tmpImages);
              tmpImages[i][field] = u;
            }
            console.log('upating event');
            setEventData({ ...eventData, tmpImages: tmpImages });
            let tmpEventData = { ...eventData };
            tmpEventData[list] = tmpImages;
            db.collection("events").doc(tmpEventData.id).set(tmpEventData);

            setCurrentEventData(tmpEventData);
            sessionStorage.setItem("eventData", JSON.stringify(tmpEventData));

            return true;
          }
        }
      // })
      // .catch((error) => {
      //   console.log(error);
      //   toast.error(error.message);
      //   return false;
      // });
  }
  const removeCloudinary = (pubId, key, disableNotifications) => {
    var remove = firebase.functions().httpsCallable("app/removeCloudinary");
    remove(pubId)
      .then((result) => {
        if (result?.data.message) {
          toast.warning(result?.data.message);
          return "";
        } else {
          if (!disableNotifications) toast.success("Image removal successful.");
          let tmpInputs = eventData;
          if (key != null && key >= 0) {
            tmpInputs[key] = "";
            let img = document.getElementById(key);
            // remove element?
            setEventData(tmpInputs);
            db.collection("events").doc(tmpInputs.id).set(tmpInputs);
            setEventData(tmpInputs);
            setCurrentEventData(tmpInputs);
            sessionStorage.setItem("eventData", JSON.stringify(tmpInputs));

          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (!disableNotifications) toast.error(error.message);
      });
  }
  const loadEventData = async () => {
    let tmpEventData = eventData;
    if(!tmpEventData || tmpEventData.id !== id) {
      tmpEventData = (await db.collection("events").doc(id).get()).data();
      setEventData(tmpEventData);
      setCurrentEventData(tmpEventData);
      sessionStorage.setItem("eventData", JSON.stringify(tmpEventData));

    }
  };
  useEffect(() => {
    loadEventData();
  }, []);
  useEffect(() => {

  }, [eventData]);
  if (!user.isAuthenticated)
    return <Navigate to="/manage/sign-in" />
  return (
    <div className='container'>
      <h1>Event Gallery</h1>
      <div className="py-3"></div>



      <div className="text-end">
        <button
          className={"btn btn-theme"}
          onClick={() => {
            let tmpGallery = eventData.eventGalleryImages;
            if (!tmpGallery) tmpGallery = [];

            tmpGallery.push("");
            setEventData({
              ...eventData,
              eventGalleryImages: tmpGallery,
            });

          }}
        >
          + Image
        </button>
      </div>
      <div className="py-2"></div>
      <div className="row">
        {eventData?.eventGalleryImages?.map((img, index) => (
          <div key={index} className="col-lg-3 col-md-4 col-6">
            <div className="gallery-image-container">
              <div className="form-group">
                <label className="form-label">Event Gallery Image (Most images should be 16:9 aspect ratio).</label>
                <div>
                  <input
                    id={"originalFileName" + index}
                    type="file"
                    multiple
                    name="originalFileName"
                    onChange={(e) => handleGalleryImageRead(e, index)}
                  />
                </div>
              </div>
              <div className={img ? "" : "d-none"}>
                <img id={"eventGalleryImage" + index}
                  className="gallery-image w-100"
                  src={img}
                />
              </div>
              <div className="text-end">
                <i className="del-icon ml-2" onClick={(e) => {
                  removeCloudinary("pcna/ezentrum/" + eventData.id + "/" + eventData.id + "_eventGalleryImage_" + index, index);
                  let eventGalleryImages = eventData.eventGalleryImages;
                  eventGalleryImages.splice(index, 1);
                  setEventData({ ...eventData, eventGalleryImages: eventGalleryImages });
                }}>
                  <DeleteIcon />
                </i>
              </div>
            </div>
          </div>
        ))}
      </div>



    </div>
  );
};
export default Sessions;
