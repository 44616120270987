import React,{useEffect} from "react";
import "../../assets/css/contact.css";
// import * as Sentry from "@sentry/react";
const Oops = () => {
  // const [inputs, setInputs] = useState({});

  useEffect(() => {
    // Sentry.captureMessage("Navigated to oops");
    document.getElementById('loading-container').classList.remove('hide-loading-container');
    setTimeout(() => {    document.getElementById('loading-container').classList.add('hide-loading-container');
  },100);
  }, []);

  // const handleChange = (e) => {
  //   setInputs({
  //     ...inputs,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  return (
    <div className="">
<div className="container small-container">
<div className="py-5"></div>
<p>There was a problem loading your page.  Please try again.</p>
      </div>
    </div>
  );
};

export default Oops;
