import React, { useContext, useEffect, useState } from "react";
import { Navigate, Routes } from 'react-router-dom'
import { firebaseAuth } from '../../../../providers/AuthProvider';
import '../../../../assets/css/reports.css';
import firebase from "firebase/compat/app";
import { GetDeviceId } from "../../../../Constants";
import moment from "moment";

const CampaignCodeRequests = () => {
  const [ eventData, setEventData ] = useState(null);
  const [eventReportData, setEventReportData] = useState(null);
  const { user } = useContext(firebaseAuth);
  if (!user.isAuthenticated)
    return <Navigate to="/manage/sign-in" />
  return (
    <div className='container'>
      <h1>Campaign Code Requests</h1>
      <div className="py-3"></div>
      <div>Coming Soon</div>
    </div>
  );
};
export default CampaignCodeRequests;