import React, { useState, useEffect, useContext, useReducer } from "react";
import { useNavigate,Link} from "react-router-dom";
import { firebaseAuth } from "../../../providers/AuthProvider";
import firebase from "firebase/compat/app";
import { db } from "../../../firebase";
import "firebase/functions";
import { DeleteIcon, EditIcon, EmailIcon, userRoles } from "../../../Constants";
import "../../../assets/css/users.css";
import { toast } from "react-toastify";

const Users = () => {
  const { user } = useContext(firebaseAuth);

  const navigate = useNavigate();

  const reducer = (state, payload) => ({ ...state, ...payload });
  const [eventList,setEventList] = useState(null);
  const [data, setData] = useReducer(reducer, { users: [], invites: [],
    isFetching: false,
    message: null, });

  const [editUser, setEditUser] = useState({ customClaims: {}, profile: {} });
  const fetchInvites = async() => {
    let invites = [];
    db.collection("invites")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          invites.push({id:doc.id,data:doc.data()});
        });
        setData({
          invites: invites,
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }
  const fetchAssignedEvents = async (uid) => {
    let assignedEvents = [];
     
    eventList.forEach(function (item) {
            
      if((item.allowedUsers && item.allowedUsers.includes(uid.toLowerCase())) || (item.usersAllowed && item.usersAllowed.lenth > 0 && item.usersAllowed.some(e => e.name === uid))) {

        assignedEvents.push(item);
      }
    });    
    return assignedEvents;
  }
  const fetchUsers = async () => {
    setData({
      users: data.users,
      isFetching: true,
      message: null,
    });

    try {
      var getUsers = firebase.functions().httpsCallable("app/listUsers");
      getUsers().then(async function (result) {
        
        let tmpEventList = [];

        
          let qs = await db.collection("events")
          .get()
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });


          qs.forEach(function (doc) {
            tmpEventList.push(doc.data());
          });
          
          result?.data?.users?.forEach(function (u) {
            console.log(u.metadata);
    let assignedEvents = [];
    tmpEventList.forEach(function (item) {
            

      if((item.allowedUsers && item.allowedUsers.includes(u.email.toLowerCase())) || (item.usersAllowed && item.usersAllowed.length > 0 && item.usersAllowed.some(e => e.name === u.email))) {
        assignedEvents.push(item);
      }
    });  
    u.assignedEvents = assignedEvents;
  });
  console.log(result.data.users[0].metadata.lastSignInTime);
        setData({
          users: result.data.users,
          isFetching: false,
          message: null,
        });
      });
    } catch (e) {
      // console.log(e);
      setData({
        users: data.users,
        isFetching: false,
        message: null,
      });
    }
  };
  useEffect(() => {

setTimeout(() => {
        // try {
        //   if(user) {
        //   var getUsers = firebase.functions().httpsCallable("app/listUsers");
        //   getUsers().then(function (result) {
   
        //     setData({
        //       users: result.data.users,
        //       fetchAssignedEvents: true,
        //       isFetching: false,
        //       message: null,
        //     });
        //   });
        // }
        // } catch (e) {
        //   // console.log(e);
        //   setData({
        //     isFetching: false,
        //     message: null,
        //   });
        // }
        fetchUsers();
        let invites = [];
        db.collection("invites")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            invites.push({id:doc.id,data:doc.data()});
          });
          setData({
            invites: invites,
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
      },20);


  }, []);

  if (!user.isAuthenticated || user.role > 1) {
    if(user.isAuthenticated && user.role > 1)
    navigate('manage/permissions');
    else
    navigate('manage/sign-in');;
  }
  function Fetching(props) {
    if (data.isFetching) {
      return <div className="alert alert-danger">Loading users.</div>;
    } else {
      return null;
    }
  }

  function Message(props) {
    if (data.message) {
      return <div className="alert alert-danger">{data.message}</div>;
    } else {
      return null;
    }
  }

  function UserRole(props) {
    let role = -1;
    if (props.customClaims) {
      role = props.customClaims.role - 1;
    }
    if (props.role) {
      role = props.role - 1;
    }
    if(role > -1) {
      let roleName = "";
      roleName = userRoles[role];
      return <div>{roleName}</div>;
    } else {
      return <div>none</div>;
    }
  }
  function toggleEditUser(uid,assignedEvents) {
    setData({
      users: data.users,
      isFetching: data.isFetching,
      message: null,
    });
    if (!editUser.uid) {
      try {
        if (uid === 0) {
          setEditUser({ customClaims: {}, profile: {} });
          fetchUsers();
        } else {
          var getUser = firebase.functions().httpsCallable("app/getUser");
          getUser(uid).then(function (result) {
            var userRef = db.collection("users").doc(result.data.uid);
            userRef
              .get()
              .then(function (doc) {
                if (doc.exists) {
                  result.data.profile = doc.data();
                } else {
                  result.data.profile = { userId: uid };
                }
                result.data.assignedEvents = assignedEvents;
                setEditUser(result.data);
              })
              .catch(function (error) {
                console.log("Error getting document:", error);
              });
          });
        }
      } catch (e) {
        console.log(e);

        setEditUser({ customClaims: {}, profile: {} });
      }
    } else {
      setEditUser({ customClaims: {}, profile: {} });
    }
  }
  function deleteUser() {
    var currentUser = firebase.auth().currentUser;
    if (currentUser.uid === editUser.uid) {
      setData({
        users: data.users,
        isFetching: data.isFetching,
        message: "The logged in user cannot be deleted.",
      });
      return;
    }
    try {
      var deleteUser = firebase.functions().httpsCallable("app/deleteUser");
      deleteUser(editUser.uid).then(function (result) {
        setEditUser({ customClaims: {}, profile: {} });
        db.collection('users').doc(editUser.uid).delete();
        db.collection("invites")
        .where("email", "==", editUser.email)
        .get()
        .then(function (snapshot) {
          snapshot.docs.forEach(d => {
            db.collection("invites")
            .doc(d.id)
            .delete();
          });
        });
        fetchUsers();
      });
    } catch (e) {
      console.log(e);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    db.collection("users").doc(editUser.profile.userId).set(editUser.profile);

    var setClaims = firebase.functions().httpsCallable("app/setClaims");
    setClaims({ uid: editUser.uid, claims: editUser.customClaims })
      .then(function (result) {
        setEditUser({ customClaims: {}, profile: {} });
        fetchUsers();
      })
      .catch(function (error) {
        console.log("Error updating claims:", error);
      });
  };
  const handleProfileChange = (e) => {
    setEditUser({
      ...editUser,
      profile: { ...editUser.profile, [e.target.name]: e.target.value },
    });
  };
  const handleClaimsChange = (e) => {
    setEditUser({
      ...editUser,
      customClaims: {
        ...editUser.customClaims,
        [e.target.name]: e.target.value,
      },
    });
  };


  function sendInvite(email) {
    var sendInvite = firebase.functions().httpsCallable("app/sendInvite");
    sendInvite(email).then(function (result) {
      // console.log(result);
      toast.success('Invitation email has been resent.');
      setData({message:'Invitation email has been resent.'});
      fetchInvites();
    });
  };

  function deleteInvite(id) {
    
    db.collection('invites').doc(id).delete();
    setData({message:'Invitation has been deleted.'})
    fetchInvites();
  }
  return (
    <div className="container">
      <div className={!editUser.uid ? "" : "d-none"}>
        <h1>Users</h1>
        <div className="py-2"></div>
        <div className="text-end">
        <Link className="btn btn-primary" to="/manage/invite">Invite User</Link>
        </div>
        <div className="py-2"></div>
        <Fetching />
        <Message />
        <div className="table-responsive">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th>Username/Email</th>
                <th>Last Login</th>
                <th className="d-none d-md-table-cell">Role</th>
                <th className="d-none d-md-table-cell">Events</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.users.map((u, i) => (
                <tr
                  key={i}
                  className="editRow"
                  onClick={() => toggleEditUser(u.uid,u.assignedEvents)}
                >
                  <td className="">{u.email}</td>
                  <td>{u.metadata?.lastSignInTime}</td>
                  <td className="d-none d-md-table-cell">
                    <UserRole customClaims={u.customClaims} />
                  </td>
                  <td className="d-none d-md-table-cell">
                    {u.email.indexOf('core12.com') > -1 ? 'All' : u.assignedEvents?.length}
                  </td>
                  <td>
                    <i className="sm">
                      <EditIcon />
                    </i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="py-2"></div>
        <h5>Pending Invitations</h5>
        <div className="py-2"></div>
        <div className="table-responsive">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th>Username/Email</th>
                <th>Last Login</th>
                <th className="d-none d-md-table-cell">Role</th>
                <th className="d-none d-md-table-cell">Events</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.invites.map((u, i) => (
                <tr
                  key={i}
                >
                  <td className="">{u.data.email}</td>
                  <td>{u.data?.metadata?.lastSignInTime}</td>
                  <td className="d-none d-md-table-cell">
                    <UserRole role={u.data.role} />
                  </td>
                  <td className="d-none d-md-table-cell">
                    {u.data.email.indexOf('core12.com') > -1 ? 'All' : '#'}
                  </td>
                  <td>
                    <button className="btn btn-link px-2 py-0" onClick={() => sendInvite(u.data.email)}>
                      <i className="sm">
                      <EmailIcon />
                    </i>
                    </button>
                    <button className="btn btn btn-link px-2 py-0" onClick={() => window.confirm("Are you sure to delete this record?")
                  ? deleteInvite(u.id)
                  : console.log("action cancelled by user.")}>
                    <i className="sm">
                      <DeleteIcon />
                    </i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
      </div>
      <div className={editUser.uid ? "" : "d-none"}>
        
        <h1>Update User</h1>
        <h5> {editUser.email}</h5>
        <div className="py-2"></div>
        <div className="row">
           {/*<div className="col-12 col-md">
            <div className="form-group">
              <label className="form-label" htmlFor="firstName">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={editUser.profile?.firstName || ""}
                onChange={handleProfileChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="lastName">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={editUser.profile?.lastName || ""}
                onChange={handleProfileChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="phone">
                Phone
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={editUser.profile?.phone || ""}
                onChange={handleProfileChange}
                className="form-control"
              />
            </div>
          </div> */}
          <div className="col-12 col-md">
            <div className="form-group">
              <label className="form-label" htmlFor="role">
                Role
              </label>
              <select
                className="form-control"
                id="role"
                name="role"
                onChange={handleClaimsChange}
                value={
                  editUser.customClaims &&
                  editUser.customClaims.role &&
                  editUser.customClaims.role
                    ? editUser.customClaims.role
                    : ""
                }
              >
                <option value=""></option>
                {userRoles.map((item, index) => (
                  <option key={index} value={index + 1}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

          </div>
        </div>
        <div className="py-3"></div>
        <h2>Assigned Events</h2>
        <div className="py-2"></div>
        {editUser.assignedEvents?.map((e, i) => (
          <div key={i} className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="form-label" htmlFor="code">
                  {e.sysname ? e.sysname : e.name} - {e.startDate} - {e.status}
                </label>
              </div>
            </div>
            </div>
          ))}
        <div className="py-3"></div>
        <div className="py-3"></div>
        <div className="row">
          <div className="col-auto">
            <button className="btn btn-theme" onClick={handleSubmit}>
              Save
            </button>
          </div>
          <div className="col-auto">
            <button
              className="btn btn-default"
              onClick={() => toggleEditUser(0)}
            >
              Cancel
            </button>
          </div>
          <div className="col text-end">
            <button
              className="btn btn-link"
              onClick={() =>
                window.confirm("Are you sure to delete this record?")
                  ? deleteUser()
                  : console.log("action cancelled by user.")
              }
            >
              <i className="sm">
                <DeleteIcon />
              </i>
            </button>
          </div>
        </div>
        <div className="py-3"></div>

        <Message />
      </div>
    </div>
  );
};

export default Users;
