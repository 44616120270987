import React, { useContext, useState, useEffect } from "react";
import { firebaseAuth } from "../../providers/AuthProvider";
import { db } from "../../firebase";
import Alert from "react-bootstrap/Alert";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const ResetPassword = (props) => {
  const {
    inputs,
    setInputs,
    errors,
    setErrors,
    user,
    handleSignout
  } = useContext(firebaseAuth);
  const [redirect, setRedirect] = useState(false);
  const [newInvite, setNewInvite] = useState(false);
  const [data, setData] = useState();
  let history = useNavigate();
  let useQuery = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (user.isAuthenticated) 
      handleSignout();
  }, [user]);
  useEffect(() => {
    let new_account = useQuery.get('new_account');
    setInputs({ confirm_password: "", password: "", new_account:new_account ? true : false });
    setErrors([]);
  }, []);


  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if(inputs.password != inputs.confirm_password) {
      setErrors(["Passwords do not match."])
      return;
    }
    let code = useQuery.get('oobCode');
    firebase.auth().confirmPasswordReset(code, inputs.password)
    .then(function() {
      history('/manage/sign-in?pw_update=1');
    })
    .catch(function(error) {
      if(error.code == "auth/expired-action-code" || error.code == "auth/invalid-action-code") {
        setNewInvite(true);
      } else {
        setErrors([error.message]);
      }
      
      
    })
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    console.log(inputs);
    setInputs((prev) => ({ ...prev, [id]: value }));
  };
  function sendInvite() {
    console.log(inputs.email);
    var sendInvite = firebase.functions().httpsCallable("app/forgotPassword");
    sendInvite(inputs.email).then(function (result) {
      
      toast.success('Invitation email has been resent.');

      setData({message:'Invitation email has been resent.'});
      
    });
  };
  return (
    <div>
              <h1>{inputs.new_account ? "Create Password" : "Reset Password"}</h1>

      {!newInvite ? 
    <form onSubmit={handleSubmit}>
      <div className="container">
        <div className="py-3"></div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="password">
                Password{" "}
              </label>
              <input
                type="password"
                id="password"
                name="password"
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="confirm_password">
                Confirm Password{" "}
              </label>
              <input
                type="password"
                id="confirm_password"
                name="confirm_password"
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="py-3"></div>
        <div className="row">
          <div className="col-auto">
            <button className="btn btn-theme" onClick={handleSubmit}>
              Register
            </button>
          </div>
        </div>
        <div className="py-2"></div>
        <div className="row">
          <div className="col-auto">
          {errors[0] ? <Alert variant="danger">{errors[0]}</Alert> : ""}

          </div>
        </div>
      </div>
    </form>   :<></>
    }       {newInvite ? <div>
      {!data?.message ? <div className="row">
        <div className="col-lg-3 col-md-6">
            <div className="pt-5 pb-3">Your invitation has expired or is invalid.  Enter your email and click the button below to send a new invitation.</div>
            <div className="form-group pb-4">
              <label className="form-label" htmlFor="email">
                Email{" "}
              </label>
              <input
                type="text"
                id="email"
                name="email"
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <button className="btn btn-theme" onClick={sendInvite}>
              Send Invite
            </button>
            </div>
            <div className="col-lg-9 col-md-6"></div>
            </div>:<></>}
            {data?.message ?
            <div className="py-5">
            {data ? <Alert variant="success">{data.message}</Alert> : ""}

              </div>:<></>}
          </div>:<></>}
          </div>
  );
};

export default ResetPassword;
